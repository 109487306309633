import * as React from 'react'
import { Link } from 'gatsby'

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex, base) => {
  if (currentIndex === 0) {
    return `/${base}/1`
  }
  return `/${base}/` + (currentIndex + 1)
}

export const Pagination = ({ pageInfo, base }) => {
  if (!pageInfo) return null
  const { currentPage, pageCount } = pageInfo

  // Create URL path for previous and next buttons
  const prevPagePath =
    currentPage - 1 === 1 ? `/${base}/1` : `/${base}/` + (currentPage - 1).toString()
  const nextPagePath = `/${base}/` + (currentPage + 1).toString()

  // Check if page is first or last to disable previous and next buttons
  const prevClass = currentPage === 1 ? 'disabled' : 'enabled'
  const nextClass = currentPage === pageCount ? 'disabled' : 'enabled'

  return (
    <ul className="pagination mt-4">
      {(currentPage !== 1) &&
        <li class="page-item">
          <a class="page-link" href={prevPagePath} aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
      }
      {Array.from({ length: pageCount }, (_, i) => {
        let numClass = 'pageNumber'
        if (currentPage === i + 1) {
          numClass = 'currentPage'
        }
        return (
          <li class={"page-item" + " " + ((currentPage === i + 1) && "active")} key={i + 1}><a class={"page-link" + " " + numClass} href={getPageNumberPath(i, base)}>{i + 1}</a></li>
        )
      })}
      {(currentPage !== pageCount) &&
        <li class="page-item">
          <a class="page-link" href={nextPagePath} aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      }
    </ul>
  )
}
