import * as React from 'react'
import * as prismicH from "@prismicio/helpers";

export const BlogListItem = ({ item }) =>  {

  const blogLink = `/blog/${item.uid}`

  let cover_img_url = item.data.cover_img.url
    
  if (new URL(cover_img_url).pathname.endsWith(".gif")) {
    cover_img_url= prismicH.asImageSrc(item.data.cover_img, {auto: "compress"})
  }

  const firstParagraph = (fullText) => {
    if (fullText != null) {
      const textLimit = 200
      const text = fullText
      const limitedText = text.substring(0, textLimit)
  
      if (text.length > textLimit) {
        return (
          <>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</>
        )
      }
      return <>{text}</>
    }
    return null
  }

  return (
    <div className="h-100 row g-0 py-4 border-bottom">
      <div className="order-2 order-md-1 col d-flex flex-column justify-content-between">
        <a href={blogLink} className="text-decoration-none text-body">
          <h4 className="fw-bold">{item.data.title}</h4>
          <p className="text-uppercase">{item.data.subtitle}</p>
          <p>{firstParagraph(item.data.body[0].primary.text.text)}</p>
        </a>
        <div className="d-flex">
          <a href={item.data.author_link.url} target="_blank">
            <img src={item.data.author_name ? (item.data.author_img.url ? item.data.author_img.url : "/icons/icon-user.png") : "/favicon.ico"} height="36" width="36" alt={item.data.author_name ? item.data.author_name : "AK Media Team"} title={item.data.author_name ? item.data.author_name : "AK Media Team"}/>
          </a>
          <div className="ms-3 d-flex flex-column justify-content-between">
            <a href={item.data.author_link.url} target="_blank" className="text-decoration-none text-body">
              <h6 className="mb-0 fw-bold">{item.data.author_name ? item.data.author_name : "AK Media Team"}</h6>
            </a>
            <div className="" style={{fontSize:"14px"}}>{item.data.date}</div>
          </div>
        </div>
			</div>
      <a href={blogLink} className="order-1 order-md-2 col-12 col-md-auto mb-3 mb-md-0 p-md-0 ms-3">
        <img className="blog-cover" src={cover_img_url} alt={item.data.cover_img.alt} title={item.data.cover_img.alt}/>
      </a>
    </div>
  );
}