import React from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import { Pagination } from '../utils/pagination'
import { BlogListItem } from '../components/BlogListItem';

const Blog = ({data, pageContext}) => {

  // const [blogItems,setBlog] = useState([])
  
  // useEffect( ()=> {
  //   const docs = data.allPrismicBlogItem || []
  //   const now = moment()
  //   const filtered = docs.nodes.filter(item => {
  //     console.log('item: ', item)
  //     const data = item.data
  //     // console.log('start: ',data.start_time)
  //     // console.log('finish: ',data.finish_time)
  //     // const startTime = moment(data.start_time)
  //     // const finishTime = moment(data.finish_time)
  //     // return (startTime < now)
  //     return true;
  //   })
  //   console.log('portfolio: ', filtered)
  //   setBlog(filtered)
  // }, [data.allPrismicBlogItem])
  
  if (!data) return null
  console.log('data: ', data)
  console.log('context: ', pageContext)

  let pageNum = ""

  if (typeof window !== 'undefined'){
    const pathName = window.location.pathname;
    pageNum = pathName.replace(/\D/g,'')
  }

  let metaPageNum = ""

  if (pageNum != "") {
    metaPageNum = " Page " + pageNum + "."
  }

  const blog = data.prismicBlog || {}
  const seo = {
    title: blog.data.seo_title + metaPageNum,
    description: blog.data.seo_description + metaPageNum,
    keywords: blog.data.seo_keywords,
  }
  console.log('seo: ', seo)

  const topMenu = data.prismicTopmenu || {}
  const footer = data.prismicFooter || {}

  const isTMWhite = true

  const docs = data.allPrismicBlogItem
  console.log('docs: ', docs);

  const blogItems = data.allPrismicBlogItem.nodes;

  const firstParagraph = (fullText) => {
    if (fullText != null) {
      const textLimit = 200
      const text = fullText
      const limitedText = text.substring(0, textLimit)
  
      if (text.length > textLimit) {
        return (
          <>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</>
        )
      }
      return <>{text}</>
    }
    return null
  }

  return (
    <Layout topMenu={topMenu.data} isTMWhite={isTMWhite} footer={footer} seo={seo}>
      <div className="bg-light">
        <div className="container" style={{paddingTop:"120px",paddingBottom:"80px"}}>
          <h1 className="page-title" style={{marginBottom:"56px"}}>{blog.data.title}</h1>
          <div className="bg-white row p-4 gy-3 gx-0 gx-sm-3 mx-0">
              {
              blogItems.map((item, index) => (
                <div key={index} className="">
                  <BlogListItem item={item} />
                </div>))
              }
          </div>
          <Pagination pageInfo={docs.pageInfo} base={'blog'}/>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyBlogQuery($limit: Int!, $skip: Int!) {
    prismicBlog {
      href
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        title
      }
      url
      type
    }

    prismicTopmenu {
      ...TopmenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }

    allPrismicBlogItem(
    sort: { fields: data___date, order: DESC }
    limit: $limit
    skip: $skip
    ) {
    nodes {
      _previewable
      uid
      url
      data {
        #seo_title
        #seo_description
        #seo_keywords
        title
        subtitle
        date
        cover_img {
          alt
          url
        }
        author_name
        author_link {
          url
        }
        author_img {
          url
        }
        #sort
        body {
          ... on PrismicBlogItemDataBodyBlogText {
            id
            slice_type
            primary {
              text {
                richText
                text
              }
            }
          }
        }
      }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
  }
`

export default withPrismicPreview(Blog)
